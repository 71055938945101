<template>
  <router-view />
</template>

<script>
import { onMounted } from "@vue/runtime-core";

export default {
  created() {
    // 记录用户首次进入页面时的URL, 主要用于IOS页面签名
    if (typeof window.entryUrl === "undefined" || !window.entryUrl) {
      window.entryUrl = location.href.split("#")[0];
    }
  },
  setup() {
    // 动态替换 Favicon
    const replaceFavicon = () => {
      if (process.env.VUE_APP_ICON_DARK) {
        const link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = require(process.env.VUE_APP_ICON_DARK);
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    };
    const disableConsole = () => {
      // 禁止右键
      document.oncontextmenu = function () {
        return false;
      };
      // 禁止F12
      document.onkeydown = function () {
        if (window.event && window.event.keyCode == 123) {
          return false;
        }
      };
    };
    onMounted(() => {
      replaceFavicon();
      disableConsole();
    });
  },
};
</script>
