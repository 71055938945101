import "bootstrap/dist/css/bootstrap.min.css";
import router from "./router";
import store from "./store";
import { createApp } from "vue";
import {
  ConfigProvider,
  Button,
  Cell,
  CellGroup,
  Empty,
  Icon,
  Loading,
  NavBar,
  List,
  Field,
  PullRefresh,
  Tabbar,
  TabbarItem,
  Image,
  Tabs,
  Tab,
  Card,
  Tag,
  Popup,
  ImagePreview,
} from "vant";
import App from "./App.vue";
import "@/assets/css/app.css";

import { getDefaultLanguage } from "@/utils/util";
import { createI18n } from "vue-i18n";
import zh from "./locales/zh";
import en from "./locales/en";
const i18n = createI18n({
  locale: getDefaultLanguage(),
  fallbackLocale: "zh",
  legacy: false,
  allowComposition: true,
  messages: {
    en: { ...en },
    zh: { ...zh },
  },
});

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(ConfigProvider)
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Empty)
  .use(Icon)
  .use(Loading)
  .use(NavBar)
  .use(List)
  .use(Field)
  .use(PullRefresh)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Image)
  .use(Tabs)
  .use(Tab)
  .use(Card)
  .use(Tag)
  .use(Popup)
  .use(ImagePreview)
  .mount("#app");
