import axios from "axios";
import qs from "qs";
// import { sendEvent } from "@/utils/aplus";
import { PURGE_AUTH } from "@/store/modules/auth";
import store from "@/store/index";
import router from "@/router/index";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    // 设置全局的baseURL
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

    // 全局将对象数据转换为queryString数据, 这样提交到后台的数据是表单, 而不是json
    // axios 会根据数据自动设置 header 的 Content-type, 无需手动设置此header
    // 取消全局设置, 因为会和文件上传冲突, 在调用post时直接使用 qs.stringify() 即可
    // axios.defaults.transformRequest = [
    //   (data) => {
    //     return qs.stringify(data);
    //   },
    // ];

    // 允许携带 cookie, 以实现 session 功能
    // axios.defaults.withCredentials = true;

    // 添加请求拦截器, 在 header 里携带 access_token 给后端
    axios.interceptors.request.use(
      function (config) {
        const currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
        config.headers = {
          ...config.headers,
          "Access-Token": currentUser ? currentUser.access_token : "",
        };
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    // 响应拦截
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status == 401) {
          store.commit(PURGE_AUTH);
          router.push({ name: "PasswordLogin" });
        }
        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    // sendEvent(resource, "CLK", params); 会导致关键数据泄漏
    return axios.post(resource, qs.stringify({ ...params, __form: "wxoa" }));
  },

  /**
   * Set the POST HTTP request for upload file
   * @param resource
   * @param formData
   * @returns {*}
   */
  upload(resource, formData) {
    return axios.post(resource, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

ApiService.init();

export default ApiService;
