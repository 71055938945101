export default {
  appname: "智生灵境",
  slogan: "专注元宇宙旅游",
  home: "首页",
  profile: "个人中心",
  search: "搜索目的地...",
  no_more: "没有更多了",
  ai_npc: "场内智能NPC",
  pulling_text: "下拉即可刷新...",
  loosing_text: "释放即可刷新...",
  loading_text: "加载中...",
  back: "返回",
  guest: "未登录用户",
  loginned_phone: "登录手机",
  my_tickets: "我的门票",
  my_collection: "喜欢的数字景点",
  help: "使用帮助",
  customer_service: "联系客服",
  about_xvr: "关于智生灵镜",
  logout: "退出登录",
  online_service: "在线客服",
  no_customer_service: "系统暂未设置客服",
  no_content: "系统内容",
  privacy_policy: "隐私政策",
  user_agreement: "用户协议",
  forbidden: "您没有权限访问当前页面",
  notfound: "您要查看的页面不存在",
  agree_all: "同意本平台用户协议和隐私政策",
  sms_login: "短信登录",
  sms_login_tips: "未注册的手机号码将自动注册",
  placeholder_phone_input: "请输入手机号码",
  placeholder_smscode_input: "请输入4-8位验证码",
  phone_number_error:"手机号码不正确",
  smscode_error:"验证码不正确",
  invitation_code: "邀请码",
  login: "登录",
  login_register: "登录/注册",
  send_sms_code: "发送验证码",
  sended: "已发送",
  resend_countdown: "{seconds}秒后可重发",
  send_failed: "发送验证码失败",
  need_agreement: "请先同意用户协议",
  password_error: "请输入不低于8位的密码",
  welcome_login: "欢迎登录",
  valid_tickets: "有效门票",
  expired_tickets: "过期门票",
  used: "已使用",
  unused: "未使用",
  check: "查看",
  my_favorite: "我喜欢的景点",
  like: "喜欢",
  favorite: "收藏",
  liked: "喜欢的",
  favorited: "收藏的",
  view_my_tickets: "查看我的门票",
  waiting_for_results: "等待结果",
  spot_introduction: "数字景点介绍",
  show_more: "显示更多",
  show_less: "显示更少",
  terms_and_conditions: "使用须知",
  more_scenic_spot: "更多景点",
  buy_with_price: "购买门票 {price} 元",
  auto_refund_tips: "24小时内未使用自动退",
  play_time_limit: "游玩时限",
  begin_time: "开始时间",
  hour: "1小时 | {count} 小时",
  ticket_checking_station: "验票站点",
  payment_method: "付款方式",
  alipay: "支付宝",
  wechatpay: "微信支付",
  paypal: "PayPal",
  payment_tool_tips: "当前环境仅支持{payment_tool}",
  phone_number: "验票手机号",
  has_logged: "您已登录",
  copy_link: "复制网址",
  copy_successful: "复制成功",
  copy_failed: "复制失败",
  price: "票价",
  confirm_pay: "确认支付",
  front_end_error: "前端脚本异常",
  front_end_error2: "前端脚本异常/页面签名失败",
  payment_successful: "支付成功",
  payment_failed: "支付失败",
  payment_cancelled: "您已取消支付",
  ticket_code: "门票核验码",
  rate: "评分",
  favorite_successful: "收藏成功",
  favorite_canceled: "取消收藏",
  like_successful: "已喜欢",
  like_canceled: "取消喜欢",
};
